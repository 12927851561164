// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_ss d_gt d_cr";
export var heroHeaderCenter = "s_gv d_gv d_cr d_dv";
export var heroHeaderRight = "s_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "s_st d_gq d_cv";
export var heroParagraphCenter = "s_gr d_gr d_cv d_dv";
export var heroParagraphRight = "s_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "s_sv d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_sw d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_sx d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_sy d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_sz d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rV q_rV";
export var heroExceptionNormal = "s_rW q_rW";
export var heroExceptionLarge = "s_rX q_rX";
export var Title1Small = "s_rw q_rw q_q1 q_q2";
export var Title1Normal = "s_rx q_rx q_q1 q_q3";
export var Title1Large = "s_ry q_ry q_q1 q_q4";
export var BodySmall = "s_rL q_rL q_q1 q_rk";
export var BodyNormal = "s_rM q_rM q_q1 q_rl";
export var BodyLarge = "s_rN q_rN q_q1 q_rm";