// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "r_jP d_jP d_ct";
export var galleryMasonryImage = "r_jN d_jN d_v d_bQ d_dz";
export var alignLeft = "r_pL d_fn d_bF d_dt";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignRight = "r_pM d_fq d_bG d_dw";
export var galleryContainer = "r_sk d_dV";
export var galleryContainerFull = "r_sl d_dS";
export var galleryRowWrapper = "r_sm d_cb";
export var galleryGuttersImage = "r_jR d_jR d_J d_ct";
export var galleryNoGuttersImage = "r_jQ d_jQ d_J d_cC";
export var galleryTextGutters = "r_jL d_jL d_cv";
export var galleryTextNoGutters = "r_jM d_jM d_cv";
export var galleryTextMasonry = "r_sn d_jL d_cv";
export var galleryImageWrapper = "r_sp d_ff d_Y";
export var descText = "r_sq d_jS d_Z d_8 d_6 d_7 d_m";
export var guttersDesc = "r_sr";